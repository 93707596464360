<template>
  <div class="row">
    <DistrictForm :submit-text="'Create District'" :header-text="'Create a new Nebraska School District'" :editing-existing="false"/>
  </div>
</template>

<script>
import DistrictForm from "@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictForm";
export default {
  name: "DistrictCreate",
  components: {DistrictForm}
}
</script>

<style scoped>

</style>